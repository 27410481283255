import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertToRaw, EditorState} from "draft-js";
// import {PopoverMenu} from "../chat/chat";
import {appSettings, Option} from "../../managers/generalManager";
import draftToHtml from "draftjs-to-html";
import {useEffect, useRef, useState} from "react";
import htmlToDraft from "html-to-draftjs";
import {getPrompt} from "../../managers/promptManager";
import {Button, ButtonGroup} from "@mui/material";
import {copyToClipboard} from "../../utils/common_tools";
import {addItemToStore, getItemFromStore} from "../../utils/IndexedDB.utils";
import '../editor/EditorComponent.css';
import logger from "../../utils/logging_services";
import {MenuOption, PopoverMenu} from "../chat/popover_menu";
import colorSchema from "../../app_theme/theme_support/colorSchema";

const buttonStyle = {
    color: colorSchema.primary.main, borderColor: colorSchema.grey["400"], '&:hover': {
        backgroundColor: colorSchema.secondary.main,
        borderColor: colorSchema.grey["400"]
    }
}

const loadSaveOptions: Option[] = [
    {value: '0', label: 'Cancel'},
    {value: '1', label: '1'},
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
    {value: '5', label: '5'},
];

const actionOptions: Option[] = [
    {value: '0', label: 'Cancel'},
    {value: 'summary', label: 'Summarize'},
    {value: 'improve', label: 'Improve my writing'},
    {value: 'mail', label: 'Convert to mail'},
    {value: 'ask', label: 'Ask a question'},
];

export interface EditorViewerProps {
    editorState: EditorState;
    showActions: boolean;
    showToolbar?: boolean;
    onEditorStateChange: (editorState: EditorState) => void;
    onSendRequest: (message: string, url: string, context: string, system_prompt: "") => void;

    onUpdateEditorState(editorState: EditorState): void;
}

export const EditorViewer = ({
                                 editorState,
                                 onEditorStateChange,
                                 onSendRequest,
                                 onUpdateEditorState,
                                 showActions,
                                 showToolbar = true,
                             }: EditorViewerProps) => {

    const [popupOption, setPopupOption] = useState<Option[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [toolBarOptions, setToolBarOptions] = useState({});
    const [actionRequestActive, setActionRequestActive] = useState(false);
    const [isMobile,] = useState<boolean>(false);
    const editorRef = useRef<Editor>(null)

    useEffect(() => {
        if (!showToolbar) {
            setToolBarOptions({});
            return;
        }

        setToolBarOptions(isMobile
            ? {
                options: ['inline', 'list'],
                inline: {inDropdown: true},
                list: {inDropdown: true}
            }
            : {
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
                inline: {inDropdown: true},
                list: {inDropdown: true},
                textAlign: {inDropdown: true},
                link: {inDropdown: true},
                history: {inDropdown: true},
            });
    }, [showToolbar, isMobile]);


    function getEditorContext() {
        const contentState = convertToRaw(editorState.getCurrentContent())
        let context = "";
        for (let block of contentState.blocks) {
            context += block.text + "\n";
        }
        return context;
    }


    // Prepare popup menu for load, workspace and action
    const handleClick = (event: any, target: string) => {
        logger.info("handleClick", target);

        if (target === 'save' || target === 'load') {
            setPopupOption(prevState => loadSaveOptions);
        } else if (target === 'action') {
            setPopupOption(prevState => actionOptions);
        }
        setAnchorEl(event.currentTarget);
    };

    // ---------------------------------------------------------------------------------------------
    async function handleLoadClicked(option: string) {
        try {
            // Retrieve the content
            const response = await getItemFromStore(appSettings.database, "data", "save_" + option);

            // If the `response` is null or undefined, it means the key was not found
            if (!response) {
                logger.info("No app_theme found for the given key");
                return;
            }

            let {contentBlocks, entityMap} = htmlToDraft(response.toString());
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const _editorState = EditorState.createWithContent(contentState);
            onUpdateEditorState(_editorState);

            logger.info("Content loaded successfully");
        } catch (error) {
            console.error("An error occurred while loading content:", error);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Use the app_theme on the current page as context for a selected request.
    const prepareAndSendRequest = (prompt: string, prompt_reference: string) => {
        logger.info("Prepare and send request: " + prompt_reference)
        let promptToExecute = prompt
        if (prompt_reference !== "") {
            promptToExecute = getPrompt(prompt_reference).prompt;
        }
        const context = getEditorContext();
        setActionRequestActive(true);
        onSendRequest(promptToExecute, "", context, "");
    }

    // Process the selected action option.
    const handleActionClicked = (option: string) => {
        logger.info("Action clicked: " + option);
        prepareAndSendRequest("", "A_" + option);
    }

    const handleOptionClick = (option: MenuOption) => {
        if (anchorEl) {
            const originId = anchorEl.id; // Get id of the button that opened the menu

            logger.info(`Option clicked: ${option.value} for action: ${originId}`);

            // Use originId to decide what to do for "Save" vs "Load"
            if (originId === 'saveButton') {
                handleSaveClicked(option.value);
            } else if (originId === 'loadButton') {
                handleLoadClicked(option.value);
            } else if (originId === 'actionButton') {
                handleActionClicked(option.value);
            }
            handleClose();
        }
    }


    async function handleSaveClicked(option: string) {
        logger.info("Save content");
        try {
            // Save the content
            const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            await addItemToStore(appSettings.database, "Data", "save_" + option, content);

            logger.info("Content saved successfully");
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    function handleCopyToClipboard() {
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        copyToClipboard(content);
    }

    const getCurrentTextSelection = (editorState: EditorState): string => {
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const focusKey = selectionState.getFocusKey();
        const currentContent = editorState.getCurrentContent();
        const isSameBlock = anchorKey === focusKey;
        const start = selectionState.getStartOffset();
        const end = selectionState.getEndOffset();

        // If the selection is within the same block, return the text directly
        if (isSameBlock) {
            const selectedText = currentContent.getBlockForKey(anchorKey).getText().slice(start, end);
            return selectedText;
        }

        // If the selection spans multiple blocks, retrieve text from all blocks
        const blockMap = currentContent.getBlockMap();
        const blockKeys = blockMap.keySeq();
        const startIndex = blockKeys.findIndex(k => k === anchorKey);
        const endIndex = blockKeys.findIndex(k => k === focusKey);
        let selectedText = '';

        // Iterate through each block within the selection range
        for (let i = startIndex; i <= endIndex; i++) {
            const blockKey = blockKeys.get(i);
            const block = blockMap.get(blockKey);
            let blockText = block.getText();

            if (i === startIndex) {
                // If it's the first block, start slicing from the selection start
                blockText = blockText.slice(start);
            } else if (i === endIndex) {
                // If it's the last block, slice up to the selection end
                blockText = blockText.slice(0, end);
            }
            // For all other blocks in the middle, take the full text

            selectedText += blockText;

            // If it's not the last block, add a newline character
            if (i < endIndex) {
                selectedText += '\n';
            }
        }

        return selectedText;
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: "1 1 auto",
            backgroundColor: colorSchema.background.default
        }}>

            {actionRequestActive && (
                <>  </>
            )}

            <Editor
                editorState={editorState}
                wrapperClassName="editorWrapper"
                editorClassName="editorContent"
                toolbarClassName="editorToolbar"
                onEditorStateChange={onEditorStateChange}
                toolbar={toolBarOptions}
                toolbarHidden={!showToolbar}
                ref={editorRef}
            />

            {showActions && (
                <div style={{
                    display: 'flex',
                    flexShrink: 0,
                    alignItems: 'center',
                    padding: '10px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    width: '80%',
                    backgroundColor: colorSchema.background.default,
                    justifyContent: 'space-between',
                    alignSelf: 'center', marginBottom: '30px'
                }} className="border">
                    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                        <Button
                            id="saveButton"
                            onClick={(event) => handleClick(event, "save")}
                            sx={buttonStyle}
                        >
                            Save
                        </Button>
                        <Button
                            id="loadButton"
                            onClick={(event) => handleClick(event, "load")}
                            sx={buttonStyle}
                        >
                            Load
                        </Button>

                        <PopoverMenu anchorEl={anchorEl} optionList={popupOption}
                                     onSelect={handleOptionClick}
                                     onClose={handleClose}/>
                        <Button
                            onClick={handleCopyToClipboard}
                            sx={buttonStyle}
                        >Copy</Button>
                        <Button
                            onClick={() => onEditorStateChange(EditorState.createEmpty())}
                            sx={buttonStyle}
                        >Clear</Button>
                        <Button
                            id="actionButton"
                            onClick={(event) => handleClick(event, "action")}
                            sx={buttonStyle}
                        >Action</Button>
                    </ButtonGroup>
                </div>
            )}
        </div>
    );
};


// import {Editor} from "react-draft-wysiwyg";
// import {ContentState, convertToRaw, EditorState} from "draft-js";
// import {PopoverMenu} from "../chat/chat";
// import {appSettings, Option} from "../../managers/generalManager";
// import draftToHtml from "draftjs-to-html";
// import {colorSchema} from "../../app_theme/theme";
// import {useEffect, useRef, useState} from "react";
// import htmlToDraft from "html-to-draftjs";
// import {getPrompt} from "../../managers/promptManager";
// import {Button, ButtonGroup} from "@mui/material";
// import {copyToClipboard} from "../../utils/common_tools";
// import {addItemToStore, getItemFromStore} from "../../utils/IndexedDB.utils";
// import '../editor/EditorComponent.css';
// import logger from "../../utils/logging_services";
//
// const buttonStyle = {
//     color: colorSchema.color1, borderColor: colorSchema.color1, '&:hover': {
//         backgroundColor: colorSchema.secondary,
//         borderColor: colorSchema.color1
//     }
// }
//
// const loadSaveOptions: Option[] = [
//     {value: '0', label: 'Cancel'},
//     {value: '1', label: '1'},
//     {value: '2', label: '2'},
//     {value: '3', label: '3'},
//     {value: '4', label: '4'},
//     {value: '5', label: '5'},
// ];
//
// const actionOptions: Option[] = [
//     {value: '0', label: 'Cancel'},
//     {value: 'summary', label: 'Summarize'},
//     {value: 'improve', label: 'Improve my writing'},
//     {value: 'mail', label: 'Convert to mail'},
//     {value: 'ask', label: 'Ask a question'},
// ];
//
// export interface EditorViewerProps {
//     editorState: EditorState;
//     showActions: boolean;
//     showToolbar?: boolean;
//     onEditorStateChange: (editorState: EditorState) => void;
//     onSendRequest: (message: string, url: string, context: string, system_prompt: "") => void;
//     onUpdateEditorState(editorState: EditorState): void;
// }
//
// export const EditorViewer = ({
//                                  editorState,
//                                  onEditorStateChange,
//                                  onSendRequest,
//                                  onUpdateEditorState,
//                                  showActions,
//                                  showToolbar = true,
//                              }: EditorViewerProps) => {
//
//     const [popupOption, setPopupOption] = useState<Option[]>([]);
//     const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//     const [toolBarOptions, setToolBarOptions] = useState({});
//     const [actionRequestActive, setActionRequestActive] = useState(false);
//     const [isMobile, ] = useState<boolean>(false);
//     const editorRef = useRef<Editor>(null)
//
//     useEffect(() => {
//
//         if ( ! showToolbar ) {
//             setToolBarOptions({});
//             return;
//         }
//
//         setToolBarOptions(isMobile ?
//             {
//                 options: ['inline', 'list'],
//                 inline: {inDropdown: true},
//                 list: {inDropdown: true}
//             } :
//             {
//                 options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
//                 inline: {inDropdown: true},
//                 list: {inDropdown: true},
//                 textAlign: {inDropdown: true},
//                 link: {inDropdown: true},
//                 history: {inDropdown: true},
//             });
//     }, []);
//
//
//     function getEditorContext() {
//         const contentState = convertToRaw(editorState.getCurrentContent())
//         let context = "";
//         for (let block of contentState.blocks) {
//             context += block.text + "\n";
//         }
//         return context;
//     }
//
//
//     // Prepare popup menu for load, workspace and action
//     const handleClick = (event: any, target: string) => {
//         logger.info("handleClick", target);
//
//         if (target === 'save' || target === 'load') {
//             setPopupOption(prevState => loadSaveOptions);
//         } else if (target === 'action') {
//             setPopupOption(prevState => actionOptions);
//         }
//         setAnchorEl(event.currentTarget);
//     };
//
//     // ---------------------------------------------------------------------------------------------
//     async function handleLoadClicked(option: string) {
//         try {
//             // Retrieve the content
//             const response = await getItemFromStore(appSettings.database, "app_theme", "save_" + option);
//
//             // If the `response` is null or undefined, it means the key was not found
//             if (!response) {
//                 logger.info("No app_theme found for the given key");
//                 return;
//             }
//
//             let {contentBlocks, entityMap} = htmlToDraft(response.toString());
//             const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
//             const _editorState = EditorState.createWithContent(contentState);
//             onUpdateEditorState(_editorState);
//
//             logger.info("Content loaded successfully");
//         } catch (error) {
//             console.error("An error occurred while loading content:", error);
//         }
//     }
//
//     const handleClose = () => {
//         setAnchorEl(null);
//     };
//
//     // Use the app_theme on the current page as context for a selected request.
//     const prepareAndSendRequest = (prompt: string, prompt_reference: string) => {
//         logger.info("Prepare and send request: " + prompt_reference)
//         let promptToExecute = prompt
//         if (prompt_reference !== "") {
//             promptToExecute = getPrompt(prompt_reference).prompt;
//         }
//         const context = getEditorContext();
//         setActionRequestActive(true);
//         onSendRequest(promptToExecute, "", context, "");
//     }
//
//     // Process the selected action option.
//     const handleActionClicked = (option: string) => {
//         logger.info("Action clicked: " + option);
//         prepareAndSendRequest("", "A_" + option);
//
//         // if (option === "mail") {
//         //     prepareAndSendRequest("", "A_Mail");
//         // } else if (option === "summary") {
//         //     prepareAndSendRequest("", "A_Summary");
//         // } else if (option === "improve") {
//         //     prepareAndSendRequest("", "A_Improve");
//         // } else if (option === "ask") {
//         //     console.log("to be implemented");
//         // }
//     }
//
//     const handleOptionClick = (option: Option) => {
//
//         if (anchorEl) {
//             const originId = anchorEl.id; // Get id of the button that opened the menu
//
//             logger.info(`Option clicked: ${option.value} for action: ${originId}`);
//
//             // Use originId to decide what to do for "Save" vs "Load"
//             if (originId === 'saveButton') {
//                 handleSaveClicked(option.value);
//             } else if (originId === 'loadButton') {
//                 handleLoadClicked(option.value);
//             } else if (originId === 'actionButton') {
//                 handleActionClicked(option.value);
//             }
//             handleClose();
//         }
//     }
//
//
//     async function handleSaveClicked(option: string) {
//         logger.info("Save content");
//         try {
//             // Save the content
//             const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
//             await addItemToStore(appSettings.database,  "Data", "save_" + option, content);
//
//             logger.info("Content saved successfully");
//         } catch (error) {
//             console.error("An error occurred:", error);
//         }
//     }
//
//     function handleCopyToClipboard() {
//         const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
//         copyToClipboard(content);
//     }
//
//     // Probleem met onderstaande code is dat de geselecteerde text niet meer geselecteerd is na het verliezen van focus
//     // Hoe kan ik terug focus leggen op de editor.
//     const getCurrentTextSelection = (editorState: EditorState): string => {
//
//         if (editorRef.current) {
//         } else {
//             logger.info("editorRef.current is null");
//             return "";
//         }
//
//         const selectionState = editorState.getSelection();
//         const anchorKey = selectionState.getAnchorKey();
//         const focusKey = selectionState.getFocusKey();
//         const currentContent = editorState.getCurrentContent();
//         const isSameBlock = anchorKey === focusKey;
//         const start = selectionState.getStartOffset();
//         const end = selectionState.getEndOffset();
//
//         // If the selection is within the same block, return the text directly
//         if (isSameBlock) {
//             const selectedText = currentContent.getBlockForKey(anchorKey).getText().slice(start, end);
//             return selectedText;
//         }
//
//         // If the selection spans multiple blocks, retrieve text from all blocks
//         const blockMap = currentContent.getBlockMap();
//         const blockKeys = blockMap.keySeq();
//         const startIndex = blockKeys.findIndex(k => k === anchorKey);
//         const endIndex = blockKeys.findIndex(k => k === focusKey);
//         let selectedText = '';
//
//         // Iterate through each block within the selection range
//         for (let i = startIndex; i <= endIndex; i++) {
//             const blockKey = blockKeys.get(i);
//             const block = blockMap.get(blockKey);
//             let blockText = block.getText();
//
//             if (i === startIndex) {
//                 // If it's the first block, start slicing from the selection start
//                 blockText = blockText.slice(start);
//             } else if (i === endIndex) {
//                 // If it's the last block, slice up to the selection end
//                 blockText = blockText.slice(0, end);
//             }
//             // For all other blocks in the middle, take the full text
//
//             selectedText += blockText;
//
//             // If it's not the last block, add a newline character
//             if (i < endIndex) {
//                 selectedText += '\n';
//             }
//         }
//
//         return selectedText;
//     };
//
//
//     return (
//         <div style={{display: 'flex', flexDirection: 'column', flex: "1 1 auto", backgroundColor: colorSchema.app_background}}>
//
//             {actionRequestActive && (
//                 <>  </>
//             )}
//
//             {/*<div*/}
//             {/*    style={{*/}
//             {/*        display: 'flex',*/}
//             {/*        flex: "1 0 auto",  // Changed to 'auto'*/}
//             {/*        flexDirection: 'column',*/}
//             {/*        borderRadius: "10px 10px 0 0",*/}
//             {/*        padding: "10px",*/}
//             {/*        // overflowY: 'auto',*/}
//             {/*        textAlign: 'center',*/}
//             {/*        justifyContent: 'flex-end',*/}
//             {/*    }}*/}
//             {/*>*/}
//             {/*    <div*/}
//             {/*        style={{*/}
//             {/*            display: 'flex',*/}
//             {/*            flex: '1 1 auto',*/}
//             {/*            textAlign: "left",*/}
//             {/*            paddingLeft: '10px'*/}
//             {/*        }}*/}
//             {/*    >*/}
//                     <Editor
//                         editorState={editorState}
//                         wrapperClassName="editorWrapper"
//                         editorClassName="editorContent"
//                         toolbarClassName="editorToolbar"
//                         onEditorStateChange={onEditorStateChange}
//                         toolbar={toolBarOptions}
//                         ref={editorRef}
//                     ></Editor>
//             {/*    </div>*/}
//             {/*</div>*/}
//
//             { showActions && (
//                 <div style={{
//                     display: 'flex',
//                     flexShrink: 0,
//                     alignItems: 'center',
//                     padding: '10px',
//                     paddingLeft: '20px',
//                     paddingRight: '20px',
//                     width: '80%',
//                     backgroundColor: colorSchema.viewer_action_background,
//                     justifyContent: 'space-between',
//                     alignSelf: 'center', marginBottom: '30px'
//                 }} className="border">
//
//                     {/*sx={{textAlign: isMobile ? 'center' : 'left', height: 'fit-content'}}>*/}
//                     <ButtonGroup variant="outlined" aria-label="outlined primary button group">
//
//                         <Button
//                             id="saveButton" // Added id
//                             onClick={(event) => handleClick(event, "save")}
//                             sx={buttonStyle}
//                         >
//                             Save
//                         </Button>
//                         <Button
//                             id="loadButton" // Added id
//                             onClick={(event) => handleClick(event, "load")}
//                             sx={buttonStyle}
//                         >
//                             Load
//                         </Button>
//
//                         <PopoverMenu anchorEl={anchorEl} optionList={popupOption}
//                                      onSelect={handleOptionClick}
//                                      onClose={handleClose}/>
//                         <Button
//                             onClick={(event) => {
//                                 handleCopyToClipboard()
//                             }}
//                             sx={buttonStyle}
//                         >Copy</Button>
//                         <Button
//                             onClick={(event) => {
//                                 onEditorStateChange(EditorState.createEmpty());
//                             }}
//                             sx={buttonStyle}
//                         >Clear</Button>
//                         <Button
//                             id="actionButton"
//                             onClick={(event) => handleClick(event, "action")}
//                             sx={buttonStyle}
//                         >Action</Button>
//
//                     </ButtonGroup>
//                 </div>
//             )}
//
//         </div>
//     )
// }
